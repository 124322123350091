<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" v-if="false">Dialog</v-btn>
    </template>
    <v-card class="no_style">
      <v-card-text>
        <InvoiceForm
          :invoice="invoice"
          @validated="save()"
          @cancel="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewInvoiceForm",
  components: {
    InvoiceForm: () => import("@/components/invoices/Form"),
  },
  data() {
    return {
      invoice: {
        type: "inventory",
        items: [],
        taxes: [],
        from_user_id: null,
        to_user_id: null,
      },
      dialog: false,
    };
  },
  methods: {
    ...mapActions("", []),
    generate(expense) {
      console.log("Fetching Expense");
      this.invoice.items = [
        {
          description: expense.conceptos,
          price: Math.abs(expense.cantidad),
          quantity: 1,
          reference_class: "expense",
          reference_id: expense.id,
        },
      ];
      if (expense.cantidad > 0) this.invoice.from_user_id = expense.user_id;
      else this.invoice.to_user_id = expense.user_id;

      this.dialog = true;
    },
    ...mapActions("invoices", ["addInvoice"]),
    save() {
      this.addInvoice(this.invoice).then(() => {
        this.$alert(this.$t("save_ok"));
        this.$emit("update");
        this.dialog = false;
      });
    },
  },
};
</script>